import React, { useState, useEffect, Suspense, Fragment, useRef, ReactComponent } from "react";
import { Link, useParams } from 'react-router-dom';
import axios from 'axios';
import { CSSTransition } from 'react-transition-group';
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import AspectRatioBox from "./aspectRatioUtil";
import Loader from '../static/svg/loader.svg';
import LoaderBanner from '../static/svg/loader-banner.svg';
import { ReactComponent as ArrowRight } from "../static/svg/arrow-right.svg";

import SwiperCore, { Navigation, Pagination, Mousewheel, EffectFade, Autoplay, FreeMode } from 'swiper';
import { Swiper, SwiperSlide } from "swiper/react";
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/mousewheel';
import 'swiper/css/effect-fade';

import configData from "../config.json";


SwiperCore.use([Autoplay])
gsap.registerPlugin(ScrollTrigger);

function Case(props) {

  
    let {caseSlug} = useParams();

    const [loaded, setLoaded] = useState(false);
    const [imageTypes, setImageTypes] = useState(['.webp','.png'])
    const [cases, setCases] = useState();
    const [caseRows, setCaseRows] = useState([]);
    const [caseRowIndex, setCaseRowIndex] = useState();
    const [obj, setObj] = useState();
    const [industries, setIndustries] = useState();
    const [recentWorkIntro, setRecentWorkIntro] = useState();
    const [values, setValues] = useState();
    const [clientIntro, setClientIntro] = useState();
    const [peopleIntro, setPeopleIntro] = useState();
    const [swipers, setSwipers] = useState([]);
    const [headingAr, setHeadingAr] = useState();

    const mediaArray = useRef([]);

    function getMedia(path, path_m) {
      let r;
      if (props.isMobile) {
        if (path_m) {
          r = configData.API_URL + path_m
        } else {
          r = configData.API_URL + path
        }
       
      } else {
        r = configData.API_URL + path
      }
    
      return r;
    }
    

    const getCaseRow = async(caseId) => {
      await axios({
        method: 'get',
        url: configData.API_URL + "/api/case-row/" + caseId + '/'
        })
        .then(response => {
          const dataJSON = response.data;
          let rows = [...caseRows];
          rows[caseRowIndex]['caserowitems'] = dataJSON.caserowitems
          setCaseRows(rows);
          // console.log('caseRow updated', rows)
        });

        let elemId = "caseRow_" + caseRowIndex;
        function scrollLoad() {
        //  console.log('scrollLoad')
          let rowTl = gsap.timeline({
            defaults: {
              duration: 0, 
              ease: "power1",
            },
            scrollTrigger: {
              trigger: "#" + elemId,
              start: "center bottom",
              end: "bottom bottom",
              scrub: 0,
              markers: false,
              onEnter: () => { setCaseRowIndex(caseRowIndex + 1) }
            }
          });

          let rowTlAnim = gsap.timeline({
            defaults: {
              duration: 0.4, 
              ease: "power1"
            },
            scrollTrigger: {
              trigger: "#caseRowBox_" + caseId,
              start: "top center+=35%",
              end: "bottom bottom",
              scrub: 1,
              markers: false
            }
          });

          let boxObj = document.getElementById('caseRowBox_' + caseId);
          rowTlAnim.fromTo(boxObj, {translateY: "40px"}, {translateY: "0"});
          rowTlAnim.fromTo(boxObj, {opacity: 0}, {opacity: 1},"<");
        }
        scrollLoad();  
    }

    const getObj = async() => {
        await axios({
          method: 'get',
          url: configData.API_URL + "/api/case/" + caseSlug
          })
          .then(response => {
            const dataJSON = response.data;
            console.log('case', dataJSON)
            setObj(dataJSON);
          });
      }

    useEffect(() => {
        if (caseRowIndex > -1) {
          // console.log('caseRow index', caseRowIndex) 
          if (obj.caserows[caseRowIndex]) {
            getCaseRow(obj.caserows[caseRowIndex].id)
          }
        }
    }, [caseRowIndex])

    useEffect(() => {
        if (obj) {
            setLoaded(true)
            document.title = obj.name + " | "  + configData.BASE_TITLE
            obj.caserows && setCaseRows(obj.caserows);
            setCaseRowIndex(0);
            // console.log('case study loaded', caseSlug, obj, obj.caserows);

            let landingAnim = gsap.timeline({
              defaults: {
                duration: 0.4, 
                ease: "power1"
              },
              scrollTrigger: {
                trigger: "#anim0",
                start: "top bottom",
                end: "bottom bottom",
                scrub: 1,
                markers: false
              }
            });
  
            let boxObj = document.getElementById('anim0');
            landingAnim.fromTo(boxObj, {translateY: "40px"}, {translateY: "0"});
            landingAnim.fromTo(boxObj, {opacity: 0}, {opacity: 1},"<");

            props.accentColor(obj.grid_color);

            function getHeadingAr() {
              let w;
              if (obj.heading_aspect_ratio) {
                w = (obj.heading_aspect_ratio/window.innerWidth) * 100
          
              } else {
                w = (64/window.innerWidth) * 100

              }
              setHeadingAr(w + '%');
            }
            getHeadingAr();

        }
    }, [obj])

    useEffect(() => {
        caseSlug && getObj();
    }, [caseSlug])


    useEffect(() => {
      props.menuColor('none');
      
      props.logoColor('fillWhite');

      console.log('case study props', props)

      // Page Meta
      document.querySelector('meta[name="description"]').setAttribute("content", "");
      document.documentElement.scrollTop = 0;

      return () => {
        swipers.forEach(obj => {
          obj.destoy();
        })
      }

    }, []);

    function addSwiper(obj) {
      let currSwipers = [ ...swipers ];
      currSwipers.push(obj);
      setSwipers(currSwipers);
    }


    function getFile(id, path, aspectRatio, aspectRatioM, fileType, text) {
      
      if (!mediaArray.current.includes(path)) {

        let span = document.getElementById('span_' + id);
        if (span) {
          
          let format = span.parentElement.dataset.format;
          let elemCount = span.parentElement.dataset.count;
          let count = elemCount;
          // console.log('elemCount', elemCount);
          if (elemCount === '4') {
            count = 2;
            // console.log('count', count);
          } 

         
          let width = "auto";
          let height = "auto";
          let paddingTop = "auto";
          switch (format) {
            case "auto":
              if (props.isMobile) {
                if (aspectRatioM) {
                  console.log('aspectRatioM', aspectRatioM)
                  // let pageWidth = "calc(92vw/" + count + ")";
                  let pageWidth = "92vw"
                  // width = "calc(" + pageWidth + " - " + ((count-1)*20) + "px)";
                  width = pageWidth;
                  paddingTop = 1/aspectRatioM * 100;
                  
                }
              } else {
                if (aspectRatio) {
                  let pageWidth = "calc(92vw/" + count + ")";
                  width = "calc(" + pageWidth + " - " + ((count-1)*20) + "px)";
                  paddingTop = 1/aspectRatio * 100;
                  
                }
              }
              
              span.setAttribute('style', 'width: ' + width + "; padding-top: calc(" + paddingTop + "%)");
              span.parentElement.setAttribute('style', "height: " + height + ";")
              break;
              case "grid4":
                if (props.isMobile) {
                  if (aspectRatioM) {
                    console.log('aspectRatioM', aspectRatioM)
                    // let pageWidth = "calc(92vw/" + count + ")";
                    let pageWidth = "92vw"
                    // width = "calc(" + pageWidth + " - " + ((count-1)*20) + "px)";
                    width = pageWidth;
                    paddingTop = 1/aspectRatioM * 100;
                    
                  }
                } else {
                  if (aspectRatio) {
                    let pageWidth = "calc(92vw/" + 4 + ")";
                    width = "calc(" + pageWidth + " - " + (20) + "px)";
                    paddingTop = 1/aspectRatio * 100;
                    
                  }
                }
                
                span.setAttribute('style', "padding-top: calc(" + paddingTop + "%)");
                span.parentElement.setAttribute('style', "height: " + height + ";")
                break;
            case "flex":
              if (props.isMobile) {
                if (aspectRatioM) {
                  
                  if (text) {
                    paddingTop = 1/aspectRatioM*100;
                    height = "calc(92vw / " + (aspectRatioM/2) + " + 10px)";
                  } else {
                    paddingTop = 1/aspectRatioM*100;
                    height = "calc(92vw / " + aspectRatioM + ")";
                  }
                  
                }
                span.setAttribute('style', "width: 92vw; padding-top: calc(" + paddingTop + "%)")
              } else {
                if (aspectRatio) {
                  height = "calc(66% / " + aspectRatio + ")";
                  paddingTop = 1/aspectRatio*100;
                }
                span.setAttribute('style', "width: 100%; padding-top: calc(" + paddingTop + "%)")
              }
             
              span.parentElement.setAttribute('style', "height: " + height + ";")
              break;
            default:
              break;
          }

          let copy = [ ...mediaArray.current ];
          copy.push(path);
          mediaArray.current = copy;
          // span.style.width = "800px"
        }

        if ((fileType === '.mp4') || (fileType === '.webm')) { 
          let target = document.getElementById(id);
          if (target) {
            setTimeout(() => {
              target.parentElement.play();
            }, 500);
            target.parentElement.parentElement.parentElement.classList.add('loaded');
          }
         
        } else {

          const getMediaFile = async (url) => {
            // console.log('mediaArray.current', mediaArray.current, mediaArray.current.includes(url))
            const response = await fetch(url);
            
            const blob = await response.blob() 
            return blob
            
          }
        
          getMediaFile(path)
              .then(result => {
                let target = document.getElementById(id);
                // console.log('result', "#" + id)
                const imageBlob = result
                const imageBase64 = URL.createObjectURL(imageBlob)
                target.setAttribute('src', imageBase64);
                target.parentElement.parentElement.classList.add('loaded');
              })
              .catch(err => {
                // log the specific error
                console.error(`Error -> ${err}`)
              })
          }
      }
    }

  function getFormat(format, format_m) {
    let r;
    if (props.isMobile) {
      r = format_m;
    } else {
      r = format
    }
    // console.log('r', props.isMobile, format, format_m)
    return r;
  }

  function handleCaption(e) {
    let target = e.target;
    // console.log('btn', target);
    if (target.classList.contains('open')) {
      target.classList.remove('open');
    } else {
      target.classList.add('open');
    }
  }

  function getCaseRowCaseName(row) {
    let format = getFormat(row.format, row.format_m);
    let result;
    switch (format) {
      case "auto":
        result = "items items" + row.itemsCount
        break;
      case "grid4":
        result = "items grid4"
        break
      default:
        result = "items textGrid auto"
        break;
    }
    return result;
  }

  function arrowRight(objId, textColor) {
    return (
      <svg width="15.167" height="15.526" viewBox="0 0 15.167 15.526">
        <defs>
          <clipPath id={"clip-path_" + objId}>
            <path data-name="Path 263" d="M0,0H15.167V-15.526H0Z" fill="none"/>
          </clipPath>
        </defs>
        <g data-name="Group 723" transform="translate(0 15.526)" clipPath={"url(#clip-path_" + objId + ")"}>
          <g data-name="Group 721" transform="translate(7.055 -0.354)">
            <path data-name="Path 261" d="M0,0,7.405-7.405-.009-14.819" fill="none" stroke={(textColor)?textColor:"#000000"} strokeWidth="1"/>
          </g>
          <g data-name="Group 722" transform="translate(0 -7.763)">
            <path data-name="Path 262" d="M0,0H14.2" fill="none" stroke={(textColor)?textColor:"#000000"}  strokeWidth="1"/>
          </g>
        </g>
      </svg>
    )
  }

   

  return (
      <CSSTransition in={loaded} timeout={200} unmountOnExit classNames="pageItem">
      
         <div id="caseContent">
            
            {obj && <div className={`row caseHeadingRow`} id="row0">
                
                <span id="row0trigger" className="animTrigger"></span>
                <span className="bannerFile">
                  {(obj.file && obj.file_m && obj.file_type === ".mp4") && <video preload="auto" poster={LoaderBanner} playsInline muted autoPlay loop src={getMedia(obj.file, obj.file_m)} ><source id={"landingRow_" + obj.id} src={getMedia(obj.file, obj.file_m)} preload={"none"} onLoad={getFile("landingRow_" + obj.id, getMedia(obj.file, obj.file_m), obj.aspect_ratio, obj.aspect_ratio_m, obj.file_type )} type="video/mp4"/></video> }

                  {(obj.file && obj.file_type === ".webp") &&  <img src={Loader} className="loading" id={"file_" + obj.id} alt={obj.file_alt ? obj.file_alt : obj.name + " banner"} onLoad={getFile("file_" + obj.id, getMedia(obj.file, obj.file_m), obj.aspect_ratio, obj.aspect_ratio_m, obj.file_type)} />}
                </span>
                <div className="column">
                  
                  <div className={`heading row0`}>
                    <AspectRatioBox tag={'h1'} classes={["caseHeading"]} fonts={props.fonts} content={obj.heading.replace('<br>', ' ')} />
               
                  </div>
                  <div className="full" id="anim0">
                    <span>
                      <div className="heading noPadding">
                        <AspectRatioBox tag={'h3'} ariaLevel={2} fonts={props.fonts} content={obj.subheading} />
                        {obj.text && <h3 className="alt" dangerouslySetInnerHTML={ {__html: (props.fonts ? (obj.text):"...")} } />}
                      </div>
                    </span>
                   
                  </div>
                    
                </div>
                
            </div>}
            <div className="column">               
              {caseRows && caseRows.map((row, i) => (
              <div 
                
                key={"caseRow_" + i}
                className={"caseRowBox anim0" + (row.heading ? " sectionRow":"") + (" loop_" + i)}
                id={"caseRowBox_" + row.id}
                style={{
                  display:(getFormat(row.format, row.format_m) === "hide")?"none":"block" 
                }}
                
              >
              <div 
                className={`row caseRow ${(row.heading ? " wHeading" : "")}`}
              >
                <div>
                  <div className="caseTrigger" id={"caseRow_" + i}></div>
                  <div className="caseTrigger" id={"caseRowAnim_" + i}></div>
                  {row.heading &&  <div className="heading">
         
                  <AspectRatioBox tag={'h2'} fonts={props.fonts} content={row.heading} />
                </div>}
                  {(getFormat(row.format, row.format_m) !== "slideshow") ?  
                  <span className={getCaseRowCaseName(row)} data-format={getFormat(row.format, row.format_m)} data-count={props.isMobile ? row.itemsCount_m : row.itemsCount}>
                    {(props.isMobile)?
                      (row.caserowitems && row.caserowitems.filter(item => item.file_m !== null).map((item, j) => (
                        <Fragment key={"caseRowItem_" + j} >
                        <span className={`itemArBox caseItem`} id={"span_file_" + item.id}>
                        <span className={`itemArBoxIn`}>
                          {(item.file && imageTypes.includes(item.file_type)) && <img src={Loader} className="loading" id={"file_" + item.id} alt={item.file_alt ? item.file_alt : obj.name + " Case Study Image " + (j+1)} onLoad={getFile("file_" + item.id, getMedia(item.file, item.file_m), item.aspect_ratio, item.aspect_ratio_m, item.file_type, item.text)} /> }
                          
                          {(item.file && item.file_type === ".mp4") && <video loop={obj.loop} playsInline muted autoPlay><source id={"file_" + item.id} src={getMedia(item.file, item.file_m)} preload={"none"} onLoad={getFile("file_" + item.id, getMedia(item.file, item.file_m), item.aspect_ratio, item.aspect_ratio_m, item.file_type, item.text )} type="video/mp4"/></video> }
                         
                          {(item.file && item.file_type === ".webm") && <video loop={obj.loop} playsInline muted autoPlay><source id={"file_" + item.id} src={getMedia(item.file, item.file_m)} preload={"none"} onLoad={getFile("file_" + item.id, getMedia(item.file, item.file_m), item.aspect_ratio, item.aspect_ratio_m, item.file_type, item.text )} type="video/webm"/></video> }
  
                        </span>
                        </span>
                        {item.text && <span className="caseRowItemText" dangerouslySetInnerHTML={ {__html: (props.fonts ? (item.text):"...")} } />}
                        </Fragment>
                      ))):
                      (row.caserowitems && row.caserowitems.filter(item => item.file !== null).map((item, j) => (
                        <Fragment key={"caseRowItem_" + j} >
                        <span className="itemArBox caseItem" id={"span_file_" + item.id}>
                        <span className="itemArBoxIn">
                          {(item.file && imageTypes.includes(item.file_type)) && <img src={Loader} className="loading" id={"file_" + item.id} alt={item.file_alt ? item.file_alt : obj.name + " Case Study Image " + (j+1)} onLoad={getFile("file_" + item.id, getMedia(item.file, item.file_m), item.aspect_ratio, item.aspect_ratio_m, item.file_type)} /> }
                          
                          {(item.file && item.file_type === ".mp4") && <video loop={item.loop} playsInline muted autoPlay><source id={"file_" + item.id} src={getMedia(item.file, item.file_m)} preload={"none"} onLoad={getFile("file_" + item.id, getMedia(item.file, item.file_m), item.aspect_ratio, item.aspect_ratio_m, item.file_type )} type="video/mp4"/></video> }
                         
                          {(item.file && item.file_type === ".webm") && <video loop={item.loop} playsInline muted autoPlay><source id={"file_" + item.id} src={getMedia(item.file, item.file_m)} preload={"none"} onLoad={getFile("file_" + item.id, getMedia(item.file, item.file_m), item.aspect_ratio, item.aspect_ratio_m, item.file_type )} type="video/webm"/></video> }
  
                        </span>
                        </span>
                        {item.text && <span className="caseRowItemText" dangerouslySetInnerHTML={ {__html: (props.fonts ? (item.text):"")} } />}
                        </Fragment>
                      )))
                    }
                   
                  </span> :
                  <div className="swiperColumn">
                      <Swiper
                        modules={[Mousewheel, EffectFade, Autoplay]}
                        init={false}
                        className="swiper-slide"
                        effect={'fade'}
                        autoplay={{
                          delay: 3000
                        }}
                        mousewheel={{
                          forceToAxis: true
                        }}
                        onSwiper={(swiper) => addSwiper(swiper)}
                        >
                        {(props.isMobile)?
                          (row.caserowitems && row.caserowitems.filter(item => item.file_m !== null).map((item, i) => (
  
                          <SwiperSlide 
                            key={"caseSlideM_" + i}
                          >
                              <img src={getMedia(item.file_m)} alt={item.image_alt} />
                            </SwiperSlide>
                          ))):
                          (row.caserowitems && row.caserowitems.filter(item => item.file !== null).map((item, i) => (
  
                            <SwiperSlide 
                              key={"caseSlide_" + i}
                            > 
                            {item.caption && <span className="swiperCaption" onClick={(e) => handleCaption(e)} id={"caption_" + item.id}><span className="captionText"><p>{item.caption}</p></span><span className="captionBtn" style={{background: (obj.grid_color)?(obj.grid_color):"#5a5a5a"}}><svg width="19.609" height="19.609" viewBox="0 0 19.609 19.609"><g transform="translate(0 9.805)"><line x2="19.609" fill="none" stroke="#fff" strokeWidth="1"/></g><g transform="translate(9.804) rotate(90)" className="plusLine"><line  x2="19.609" fill="none" stroke="#fff" strokeWidth="1"/></g></svg></span></span>}
                                <img src={getMedia(item.file, item.file_m)} alt={item.image_alt} />
                               
                              </SwiperSlide>
                            )))
                        }
                        
                      </Swiper>  
                     
                    </div>}



                  </div>

                </div>
              </div>
              ))}

      
                  
                    
                  
           
            </div>

            {obj && (obj.cases.length > 0) && 
              <div className="row">
                <div className="column">
                <div className="heading">
                <h2>Relevant Case Studies</h2>
              </div>
                </div>
                  <div className="swiperColumn squareSlides">
                
                      <Swiper
                        modules={[Navigation,Pagination, Mousewheel, FreeMode]}
                        init={false}
                        breakpoints={{
                          0: {
                            slidesPerView: 1.25,
                            spaceBetween: 0
                          },
                          820: {
                            slidesPerView: 1.25,
                            spaceBetween: 15
                          },
                          1100: {
                            slidesPerView: 2.25,
                            spaceBetween: 30
                          },
                          1600: {
                            slidesPerView: 2.25,
                            spaceBetween: 30
                          },
                          2400: {
                            slidesPerView: 2.25,
                            spaceBetween: 80
                          }
                        }}
                        className="swiper-slide"
                        freeMode={{
                          enabled: true,
                          sticky: true
                        }}
                        mousewheel={{
                          forceToAxis: true
                        }}
                        slideToClickedSlide={true}
                        pagination={{ clickable: true, type: "fraction", el: "#swiperFraction1" }}
                        navigation={{nextEl: "#swiperNext1", prevEl: "#swiperPrev1"}}
                        // onSwiper={(swiper) => setEngageSwiper(swiper)}
                        >
                        {obj && obj.cases && obj.cases.map((related, i) => (
                          <SwiperSlide 
                            key={"related_work_" + i}
                          >
                          <Link to={related.get_absolute_url} onClick={() => document.scrollTop()} className={"slide"}>
                          <span className="slideInner" id={"recentWorkSlide" + i} style={{
                            backgroundColor: "#000000",
                            color: "#ffffff"
                          }}>
                            <img src={getMedia(related.thumbnail, related.thumbnail_m)} alt={related.image_alt ? related.image_alt : related.heading} />
                            <div className="textRow" style={{backgroundColor: "#000000"}}>
                              <h3>{related.subheading}</h3>
                              {related.intro && <p dangerouslySetInnerHTML={ {__html: (props.fonts ? (related.intro):"")} } />}
                              <div className="learn">
                              <span>See more</span>
                             {arrowRight("_related_" + i, "#FFFFFF")}
                              </div>
                            </div>
                    
                            </span>
                        </Link>
                            </SwiperSlide>
                          ))}
                          <SwiperSlide></SwiperSlide>
                      </Swiper>  
                      <div className="column">
                        <div className="swiperNav">
                      
                            <div className="swiperPrev" id="swiperPrev1">Prev</div>
                            <div className="swiperFraction" id="swiperFraction1" />
                            <div className="swiperNext" id="swiperNext1">Next</div>
                          </div>
                      </div> 
                    </div>
                    <span id="row2triggerEnd" className="animTrigger1"></span>
                          
            
            </div>
            }
           
            {obj && <style>{obj.theme}</style>}
        </div>
      </CSSTransition>
  );
}

export default Case;


// 